import axios from "@/utils/axios";
import moment from 'moment';

const getAll = async (empresa_id) => {
    const result = (await axios.get('/caixas', { params: { empresa_id } })).data;
    return result;
}

const getControle = async (empresa_id, data, cod_caixa = null) => {
    // console.log("cx cotrl", data);

    if (!data || typeof data == 'undefined' || data.toString().toLowerCase() == 'invalid date') {
        data = moment().format("YYYY-MM-DD")
    }
    const result = (await axios.get('/caixas/controle', { params: { empresa_id, data, cod_caixa } })).data;
    return result;
}

const getMotosControle = async (empresa_id, data, cod_moto = null) => {
    if (!data || typeof data == 'undefined' || data.toString().toString().toLowerCase() == 'invalid date') {
        data = moment().format("YYYY-MM-DD")
    }
    const result = (await axios.get('/caixas/controle/motos', { params: { empresa_id, data, cod_moto } })).data;
    return result;
}
///caixas/controle/delMoto
const delMotoControle = async (cod_entrada, cod_moto,data) => {

    const result = (
        await axios.post('/caixas/controle/delMoto', {
            cod_entrada, cod_moto, data, noVerifyOrders: true
        })).data;
    return result;
}

const changeValorAbertura = async (cod_controle, valor, moto) => {
    valor = parseFloat(valor.replace("R$", "").replace(".", "").replace(",", "."));
    const res = (await axios.post('/caixas/controle/changeValorAbertura', { cod_controle, valor, moto })).data;
    return res;
}

const storeSaida = async (saida) => {
    saida.valor = parseFloat(saida.valor.replace("R$", "").replace(".", "").replace(",", "."));
    return (await axios.post('/caixas/saidas', saida)).data;
}
const getSaida = async (cod_empresa, tipo, cod_caixa, data) => {
    // console.log('saidas---', { cod_empresa, tipo, cod_caixa, data });
    if (!data || typeof data == 'undefined' || data.toString().toLowerCase() == 'invalid date') {
        data = moment().format("YYYY-MM-DD")
    }
    return (await axios.get('/caixas/saidas', { params: { cod_empresa, tipo, cod_caixa, data } })).data;
}
const delSaida = async (saida) => {
    return (await axios.delete('/caixas/saidas', { params: { cod_saida: saida.cod_saida } })).data;
}

const getFechamentos = async (cod_empresa, data, cod_caixa, cod_moto, tipo, fromPedido = false) => {
    // console.log('send', { cod_empresa, data, cod_caixa, cod_moto, tipo, fromPedido })
    if (!data || typeof data == 'undefined' || data.toString().toLowerCase() == 'invalid date') {
        data = moment().format("YYYY-MM-DD")
    }
    return (await axios.get('/caixas/fechamento/get', { params: { cod_empresa, data, cod_caixa, cod_moto, tipo, fromPedido } })).data.data;
}
const storeFechamento = async (data) => {
    // if (!data. || typeof data == 'undefined' || data.toString().toLowerCase() == 'invalid date') {
    //     data = moment().format("YYYY-MM-DD")
    // }
    // console.log(data);
    return (await axios.post('/caixas/fechamento/store', data)).data;
}

const getConferencia = async (data, cod_empresa, cod_caixa, cod_moto) => {
    if (!data || typeof data == 'undefined' || data.toString().toLowerCase() == 'invalid date') {
        data = moment().format("YYYY-MM-DD")
    }
    return (await axios.get('/caixas/conferencia/get', { params: { data, cod_empresa, cod_caixa, cod_moto } })).data;
}
const storeConferencia = async (data) => {
    return (await axios.post('/caixas/conferencia/store', data)).data;

}
const excluirConferencia = async (cod_conferencia) => {
    return (await axios.post('/caixas/conferencia/delete', { cod_conferencia })).data;
}

const getResumo = async (data) => {
    return ((await axios.post('/caixas/resumo', data)).data).data;
}
const getSaidasResumo = async (data) => {
    return ((await axios.post('/caixa/resumo/saidas', data)).data).data;

}
const getValesResumo = async (data) => {
    return ((await axios.post('/caixa/resumo/vales', data)).data).data;

}

const excluirFechamento = async (data) => {
    return ((await axios.post('/caixa/excluirFechamento', data)).data).data;

}

const storeCaixasAnexos = async (data) => {
    return ((await axios.post('/caixas/storeAnexos', data)).data).data;

}

const changeCaixaPedidos = async (cod_caixa_old, cod_caixa_new, data, cod_empresa) => {
    return ((await axios.post('/caixas/changeCaixaPedidos', { cod_caixa_old, cod_caixa_new, data, cod_empresa })).data).data;
}

const getMesas = async (filter) => {
    return (await axios.post('/pedidos/getMesa', filter)).data;

}


export default {
    getAll,
    storeSaida,
    getSaida,
    delSaida,
    getControle,
    changeValorAbertura,
    getMotosControle,
    getFechamentos,
    storeFechamento,
    getConferencia,
    getResumo,
    getSaidasResumo,
    getValesResumo,
    storeConferencia,
    excluirConferencia,
    excluirFechamento,
    storeCaixasAnexos,
    changeCaixaPedidos,
    delMotoControle,
    getMesas
}
