var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[(!_vm.hideEmpresa)?_c('div',{staticClass:"col"},[_c('b-form-group',[_c('label',[_c('i',{staticClass:"fas fa-store"}),_vm._v(" Empresa")]),_c('v-select',{attrs:{"options":_vm.empresas,"label":"nome","reduce":(emp) => emp.cod_empresa,"value":_vm.empresa,"loading":_vm.loadingEmpresa},on:{"input":_vm.handleInputEmpresa},scopedSlots:_vm._u([{key:"search",fn:function({ attributes, events }){return [_c('input',_vm._g(_vm._b({class:[
                'vs__search rounded',
                _vm.empresaState
                  ? 'isInvalid'
                  : '',
              ]},'input',attributes,false),events))]}},{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" Nenhum resultado para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Não encontramos nenhuma empresa 😕.")])]}}],null,false,1079746422)})],1)],1):_vm._e(),(!_vm.hideCaixa)?_c('div',{staticClass:"col"},[_c('b-form-group',[_c('label',[_vm._v("Caixa")]),_c('v-select',{attrs:{"options":_vm.caixas,"label":"nome","value":_vm.caixa,"reduce":(cxa) => cxa.cod_caixa,"loading":_vm.loadingCaixa},on:{"input":_vm.handleInputCaixa},scopedSlots:_vm._u([{key:"search",fn:function({ attributes, events }){return [_c('input',_vm._g(_vm._b({class:[
                'vs__search rounded',
                _vm.clinicaState
                  ? 'isInvalid'
                  : '',
              ]},'input',attributes,false),events))]}},{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" Nenhum resultado para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Nenhum caixa para a empresa selecionada 😕.")])]}}],null,false,1456610485)})],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }